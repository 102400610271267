






















import {
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useRoute,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';

import {
  CacheTagPrefix,
  useCache
} from '@vue-storefront/cache';

import { getMetaInfo } from '~/helpers/getMetaInfo';

import {
  CountrySelectorItem,
  useContent,
  useCurrency, useMagentoConfiguration,
  useUiNotification,
  useWebsiteCode
} from '~/composables';

import {
  MainSlider,
  PromoBannerWithMeta,
  PresentationProduct,
  FashionSlider,
  Boutiques,
  BoutiqueSingle
} from '~/components';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

import tokenValidation from '~/helpers/tokenValidation';
import { PromoBannerGroupType } from '~/constants';
import { useCountriesStore } from "~/modules/countries/stores/countries";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: 'HomePage',
  components: {
    MainSlider,
    PromoBannerWithMeta,
    PresentationProduct,
    FashionSlider,
    Boutiques,
    BoutiqueSingle,
    LoadWhenVisible,
  },
  head() {
    return getMetaInfo(this.page);
  },
  middleware: ['url-action-handler'],
  setup() {
    const {
      app,
      localeRoute
    } = useContext();

    const {
      isInt,
      isAu,
      isMe
    } = useWebsiteCode();

    const {
      currency: currencies,
      change: changeCurrency,
    } = useCurrency();

    const { countriesList } = storeToRefs(useCountriesStore());

    const {
      selectedCurrency,
    } = useMagentoConfiguration();

    const router = useRouter();
    const route = useRoute();
    const { send: sendNotification } = useUiNotification();
    const { loadPage } = useContent();
    const { addTags } = useCache();

    const page = ref(null);

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    const sendSuccessSubscribeMessage = () => {
      sendNotification({
        id: Symbol('subscribe_success'),
        message: app.i18n.tc('You have successfully signed up for our newsletter'),
        type: 'success',
        icon: 'check',
      });
    };

    const sendErrorSubscribeMessage = () => {
      sendNotification({
        id: Symbol('subscribe_error'),
        message: app.i18n.tc('Unfortunately, your attempt to sign up for our newsletter has failed. Please try again'),
        type: 'danger',
        icon: 'info_shield',
        persist: false,
      });
    };

    onMounted(async () => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);

      if (route.value.query?.ref && route.value.query.ref === 'subscriberConfirmation') {
        const isTokenValid = await tokenValidation(ref({
          ref: route.value.query.ref,
          id: route.value.query.id,
          t: route.value.query.t,
        }));
        if (isTokenValid) sendSuccessSubscribeMessage();
        else sendErrorSubscribeMessage();
        await router.replace(localeRoute({ query: null }));
      }

      const queryCurrency = route.value.query?.currency as string;
      if (queryCurrency) {
        if (selectedCurrency.value !== queryCurrency) {
          if (queryCurrency === 'SAR' || queryCurrency === 'AED') {
            const selectedCountry: CountrySelectorItem = countriesList.value.items.filter(country => country.currency_code === queryCurrency)[0];
            if (selectedCountry && selectedCountry.code && selectedCountry.icon_url) {
              app.$cookies.set('vsf-selected-country', selectedCountry.code, { path: app.$config.websiteCode});
              app.$cookies.set('vsf-selected-country-icon', selectedCountry.icon_url, { path: app.$config.websiteCode});
            }
          }

          await changeCurrency({ id: queryCurrency });
        }
        await router.replace(localeRoute({ query: null }));
      }
    });

    return {
      page,
      isInt,
      isAu,
      isMe,
      PromoBannerGroupType
    };
  }
});
